@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;800&display=swap');

body {
  padding: 0;
  margin: 0;
  font-family: 'Manrope', sans-serif;
  background: url(../public/images/bg.png);
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.20);
}

div::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-track {
  border-radius: 14px;
}

::-webkit-scrollbar-thumb {
  background: #0083ED;
}